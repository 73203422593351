import {
  MoveResource,
  Nullable,
  request,
  ResponseBody,
} from "@tager/admin-services";

import {
  DealerInterface,
  DealerFullInterface,
  DealerContentFinanceItem,
  DealerContentServiceItem,
} from "./typings";

export interface CountData {
  count: number;
}

export function getDealersCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/dealers/count" });
}

export function getDealers(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<DealerInterface>>> {
  return request.get({ path: "/admin/dealers", params });
}

export function getDealer(
  id: string | number
): Promise<ResponseBody<DealerFullInterface>> {
  return request.get({ path: `/admin/dealers/${id}` });
}

export type DealerCreatePayload = {
  name: string;
  apiDealer: number | null;
};

export function createDealer(
  payload: DealerCreatePayload
): Promise<ResponseBody<DealerFullInterface>> {
  return request.post({
    path: `/admin/dealers`,
    body: payload,
  });
}

export type DealerUpdatePayload = {
  type: Nullable<string>;
  serviceAvailable: boolean;
  name: string;
  shortDescription: string;
  apiDealer: Nullable<number>;
  urlSlug: Nullable<string>;
  brands: number[];
  hideVin: boolean;
  hidePrices: boolean;

  legalName: Nullable<string>;
  image: Nullable<string>;
  imageCover: Nullable<string>;
  imageCoverMobile: Nullable<string>;
  pageTitle: Nullable<string>;
  pageDescription: Nullable<string>;
  seoText: Nullable<string>;
  disablePartsOrder: boolean;

  heroBannerTitle: Nullable<string>;
  heroBannerDescription: Nullable<string>;

  aboutBlockImage: Nullable<string>;
  aboutBlockDescription: Nullable<string>;

  isRequestsBannersActive: boolean;

  stockBrands: number[];

  smallBannerImage: Nullable<string>;
  smallBannerTitle: Nullable<string>;
  smallBannerDescription: Nullable<string>;

  testDriveImage: Nullable<string>;
  testDriveDescription: Nullable<string>;

  financeTitle: Nullable<string>;
  financeDescription: Nullable<string>;
  financeButtonLabel: Nullable<string>;
  financeButtonLink: Nullable<string>;
  financeAllItemsText: Nullable<string>;
  financeItems: Array<DealerContentFinanceItem>;

  serviceTitle: Nullable<string>;
  serviceButtonLabel: Nullable<string>;
  serviceButtonLink: Nullable<string>;
  serviceItems: Array<DealerContentServiceItem>;

  isJournalActive: boolean;
  isCommonBannersActive: boolean;
  isReviewsActive: boolean;
  isVideosActive: boolean;
};

export function updateDealer(
  id: number | string,
  payload: DealerUpdatePayload
): Promise<ResponseBody<DealerFullInterface>> {
  return request.put({
    path: `/admin/dealers/${id}`,
    body: payload,
  });
}

export function deleteDealer(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/dealers/${id}` });
}

export const moveDealer: MoveResource = (id, direction) => {
  return request.post({ path: `/admin/dealers/${id}/move/${direction}` });
};

type ApiDealer = {
  id: number;
  name: string;
  locations: Array<{
    id: number;
    name: string;
  }>;
};

export function getApiDealers(): Promise<ResponseBody<Array<ApiDealer>>> {
  return request.get({ path: "/admin/dealers/api-dealers" });
}

type Brand = {
  id: number;
  name: string;
};

export function getBrands(): Promise<ResponseBody<Array<Brand>>> {
  return request.get({ path: "/admin/dealers/brands" });
}

import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";
import { createId, Nullable } from "@tager/admin-services";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import {
  DealerContentFinanceItem,
  DealerContentServiceItem,
  DealerFullInterface,
  DealerType,
} from "@/modules/dealers/typings";
import { DealerUpdatePayload } from "@/modules/dealers/requests";

export type FormValues = {
  type: DealerType | null;
  serviceAvailable: boolean;
  name: string;
  shortDescription: string;
  urlSlug: string;
  apiDealer: Nullable<OptionType<number>>;
  brands: Array<OptionType<number>>;
  hideVin: boolean;
  hidePrices: boolean;

  legalName: string | null;
  image: Nullable<SingleFileInputValueType>;
  imageCover: Nullable<SingleFileInputValueType>;
  imageCoverMobile: Nullable<SingleFileInputValueType>;
  pageTitle: string | null;
  pageDescription: string | null;
  seoText: string | null;
  disablePartsOrder: boolean;

  heroBannerTitle: Nullable<string>;
  heroBannerDescription: Nullable<string>;

  aboutBlockImage: Nullable<SingleFileInputValueType>;
  aboutBlockDescription: Nullable<string>;

  isRequestsBannersActive: boolean;

  stockBrands: Array<OptionType<number>>;

  smallBannerImage: Nullable<SingleFileInputValueType>;
  smallBannerTitle: Nullable<string>;
  smallBannerDescription: Nullable<string>;

  testDriveImage: Nullable<SingleFileInputValueType>;
  testDriveDescription: Nullable<string>;

  financeTitle: Nullable<string>;
  financeDescription: Nullable<string>;
  financeButtonLabel: Nullable<string>;
  financeButtonLink: Nullable<string>;
  financeAllItemsText: Nullable<string>;

  serviceTitle: Nullable<string>;
  serviceButtonLabel: Nullable<string>;
  serviceButtonLink: Nullable<string>;

  isJournalActive: boolean;
  isCommonBannersActive: boolean;
  isReviewsActive: boolean;
  isVideosActive: boolean;
};

export function getDealerFormValue(
  dealer: DealerFullInterface,
  apiDealersOptions: Array<OptionType<number>>,
  brandsOptions: Array<OptionType<number>>
): FormValues {
  return {
    type: dealer.type ?? null,
    serviceAvailable: dealer.serviceAvailable,
    name: dealer.name || "",
    shortDescription: dealer.shortDescription || "",
    urlSlug: dealer.urlSlug || "",
    apiDealer:
      apiDealersOptions.find((item) => item.value === dealer.apiDealer) || null,
    brands:
      brandsOptions?.filter((item) => dealer.brands?.includes(item.value)) ||
      [],
    hideVin: dealer.hideVin,
    hidePrices: dealer.hidePrices,

    legalName: dealer.legalName,
    image: dealer.image
      ? {
          id: createId(),
          file: dealer.image,
          caption: "",
        }
      : null,
    imageCover: dealer.imageCover
      ? {
          id: createId(),
          file: dealer.imageCover,
          caption: "",
        }
      : null,
    imageCoverMobile: dealer.imageCoverMobile
      ? {
          id: createId(),
          file: dealer.imageCoverMobile,
          caption: "",
        }
      : null,

    pageTitle: dealer.pageTitle,
    pageDescription: dealer.pageDescription,
    seoText: dealer.seoText,
    disablePartsOrder: dealer.disablePartsOrder,

    heroBannerTitle: dealer.content.heroBannerTitle,
    heroBannerDescription: dealer.content.heroBannerDescription,

    aboutBlockImage: dealer.content.aboutBlockImage
      ? {
          id: createId(),
          file: dealer.content.aboutBlockImage,
          caption: "",
        }
      : null,
    aboutBlockDescription: dealer.content.aboutBlockDescription,

    isRequestsBannersActive: dealer.content.isRequestsBannersActive,

    stockBrands:
      brandsOptions?.filter((item) =>
        dealer.content.stockBrands?.includes(item.value)
      ) || [],

    smallBannerImage: dealer.content.smallBannerImage
      ? {
          id: createId(),
          file: dealer.content.smallBannerImage,
          caption: "",
        }
      : null,
    smallBannerTitle: dealer.content.smallBannerTitle,
    smallBannerDescription: dealer.content.smallBannerDescription,

    testDriveImage: dealer.content.testDriveImage
      ? { id: createId(), file: dealer.content.testDriveImage, caption: "" }
      : null,
    testDriveDescription: dealer.content.testDriveDescription,

    financeTitle: dealer.content.financeTitle,
    financeDescription: dealer.content.financeDescription,
    financeButtonLabel: dealer.content.financeButtonLabel,
    financeButtonLink: dealer.content.financeButtonLink,
    financeAllItemsText: dealer.content.financeAllItemsText,

    serviceTitle: dealer.content.serviceTitle,
    serviceButtonLabel: dealer.content.serviceButtonLabel,
    serviceButtonLink: dealer.content.serviceButtonLink,

    isJournalActive: dealer.content.isJournalActive,
    isCommonBannersActive: dealer.content.isCommonBannersActive,
    isReviewsActive: dealer.content.isReviewsActive,
    isVideosActive: dealer.content.isVideosActive,
  };
}

export function convertPageFormValuesToUpdatePayload(
  values: FormValues,
  financeItems: RepeaterField,
  serviceItems: RepeaterField
): DealerUpdatePayload {
  return {
    type: values.type,
    serviceAvailable: values.serviceAvailable,
    name: values.name,
    shortDescription: values.shortDescription,
    urlSlug: values.urlSlug,
    apiDealer: values.apiDealer?.value || null,
    brands: values.brands.map((item) => item.value),
    hideVin: values.hideVin,
    hidePrices: values.hidePrices,

    legalName: values.legalName,
    image: values.image?.file.id || null,
    imageCover: values.imageCover?.file.id || null,
    imageCoverMobile: values.imageCoverMobile?.file.id || null,
    pageTitle: values.pageTitle,
    pageDescription: values.pageDescription,
    seoText: values.seoText,
    disablePartsOrder: values.disablePartsOrder,

    heroBannerTitle: values.heroBannerTitle,
    heroBannerDescription: values.heroBannerDescription,

    aboutBlockImage: values.aboutBlockImage?.file.id || null,
    aboutBlockDescription: values.aboutBlockDescription,

    isRequestsBannersActive: values.isRequestsBannersActive,

    stockBrands: values.stockBrands.map((item) => item.value),

    smallBannerImage: values.smallBannerImage?.file.id || null,
    smallBannerTitle: values.smallBannerTitle,
    smallBannerDescription: values.smallBannerDescription,

    testDriveImage: values.testDriveImage?.file.id || null,
    testDriveDescription: values.testDriveDescription,

    financeTitle: values.financeTitle,
    financeDescription: values.financeDescription,
    financeButtonLabel: values.financeButtonLabel,
    financeButtonLink: values.financeButtonLink,
    financeAllItemsText: values.financeAllItemsText,
    financeItems: financeItems.value.map((item) => {
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        image: item.value[0].value?.file.id || null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title: String(item.value[1].value),

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        text: String(item.value[2].value),

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        link: String(item.value[3].value),
      };
    }),

    serviceTitle: values.serviceTitle,
    serviceButtonLabel: values.serviceButtonLabel,
    serviceButtonLink: values.serviceButtonLink,
    serviceItems: serviceItems.value.map((item) => {
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        image: item.value[0].value?.file.id || null,

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        text: String(item.value[1].value),

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        link: String(item.value[2].value),
      };
    }),

    isJournalActive: values.isJournalActive,
    isCommonBannersActive: values.isCommonBannersActive,
    isReviewsActive: values.isReviewsActive,
    isVideosActive: values.isVideosActive,
  };
}

export const FinanceFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Финансовый сервис - Продукт",
  meta: {
    view: "BLOCK",
    hideCount: true,
    titleFormatter: (values: any) => {
      return String(values[1].value) || null;
    },
  },
  fields: [
    {
      name: "icon",
      type: "FILE",
      label: "Картинка",
      meta: {
        scenario: "dealer-image",
      },
    },
    {
      name: "title",
      type: "STRING",
      label: "Заголовок",
    },
    {
      name: "text",
      type: "STRING",
      label: "Текст",
    },
    {
      name: "link",
      type: "STRING",
      label: "Ссылка",
    },
  ],
};

export const ServiceFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Сервис - Продукты",
  meta: {
    view: "BLOCK",
    hideCount: true,
    titleFormatter: (values: any) => {
      return String(values[1].value) || null;
    },
  },
  fields: [
    {
      name: "icon",
      type: "FILE",
      label: "Картинка",
      meta: {
        scenario: "dealer-image",
      },
    },
    {
      name: "text",
      type: "STRING",
      label: "Текст",
    },
    {
      name: "link",
      type: "STRING",
      label: "Ссылка",
    },
  ],
};
